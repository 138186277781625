import { HomeCard } from "@/components/AnimateCard/HomeCard";
import { navigatorMenus } from "@/utils/routes";
import { GetStaticProps } from "next";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import AdBanner from "@/components/AdBanner";
import * as React from "react";
import { Slogan } from "@/components/Slogan";

export default function Index() {
  const { t } = useTranslation("common");
  const { t: td } = useTranslation("description");

  return (
    <div className="relative w-full">
      <div className="content p-1 md:p-5">
        <Slogan />
        {Object.keys(navigatorMenus).map((key, index) => {
          return (
            <div className="relative" data-index={index} key={key}>
              <h2 className="home-title mb-8">{t(`tool.${key}`)}</h2>
              <div className="grid grid-cols-1 md:grid-cols-4 gap-x-0 md:gap-x-4">
                {navigatorMenus[key]?.length &&
                  navigatorMenus[key].map((component, inx) => {
                    return (
                      <HomeCard
                        title={component.label}
                        className="mb-4"
                        key={component.path}
                        data-index={inx}
                        description={component.desc}
                        path={component.path}
                      >
                        <div className="flex flex-col">
                          <p className="text-[13px] text-gray-500">
                            {td(component.desc)}
                          </p>
                        </div>
                      </HomeCard>
                    );
                  })}
              </div>
              {index === 1 && (
                <AdBanner
                  className="w-full"
                  data-ad-slot="2131063994"
                  data-ad-format="auto"
                  data-id="456"
                  data-full-width-responsive="true"
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

type Props = {
  // Add custom props here
};

export const getStaticProps: GetStaticProps<Props> = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale ?? "en", [
      "common",
      "description",
      "title",
      "keywords"
    ]))
  }
});
