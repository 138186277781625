import React, { useEffect, useMemo, useState } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { toaster, Pane } from "evergreen-ui";
import clsx from "clsx";
import { cn } from "@/utils/utils";
import copy from "clipboard-copy";
import { Copy } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useRouter } from "next/router";

import styles from "./HomeCard.module.css";
import Link from "next/link";
import { useTranslation } from "next-i18next";

export const HomeCard = ({
  children,
  title,
  description,
  className,
  path
}: {
  children: React.ReactNode;
  path: string;
  title: string;
  className?: string;
  description?: string;
  footer?: string;
}) => {
  const { t } = useTranslation("common");
  const { t: tt } = useTranslation("title");
  const _title = tt(description) === description ? title : tt(description);
  const router = useRouter();

  const [baseUrl, setBaseUrl] = useState(process.env.VERCEL_URL || "");
  useEffect(() => {
    if (window.location) {
      setBaseUrl(window.location.origin);
    }
  }, []);

  return (
    <Link
      className="group block relative w-full transition pb-10"
      href={path}
      title={_title}
    >
      <Card
        className={cn(
          "relative p-0 h-[218px] rounded-xl",
          styles["home-card"],
          className
        )}
      >
        <Pane
          is="div"
          className={cn(
            "w-full h-full relative p-4 bg-card rounded-xl",
            styles["home-card-content"]
          )}
        >
          {_title && (
            <CardHeader className={clsx("relative z-1 p-1")}>
              <CardTitle className="text-md group-hover:underline transition">
                {_title}
              </CardTitle>
              {/* {description && <CardDescription>{description}</CardDescription>} */}
            </CardHeader>
          )}
          <CardContent className={clsx("relative z-1 p-1 mt-1")}>
            {children}
          </CardContent>
          <Pane
            is="div"
            className="absolute bottom-0 left-0 flex h-8 w-full rounded-md border-t border-input bg-background px-3 pr-8 py-1 text-sm ring-offset-background"
          >
            <p className="transition w-full truncate group-hover:underline">
              {path}
            </p>
            <Button
              className="w-4 h-4 absolute right-1 top-[50%] translate-y-[-50%] rounded"
              variant="outline"
              size="icon"
              onClick={e => {
                const _path = `${
                  router.locale === "en" ? "" : `/${router.locale}`
                }${path}`;
                e.preventDefault();
                copy(`${baseUrl}${_path}`);
                toaster.success(t("editor.copy-message"));
              }}
            >
              <Copy className="h-2.5 w-2.5" />
            </Button>
          </Pane>
        </Pane>
        {/* <div className="relative w-full">
          <div className={styles["card-area"]}>
            <ul className={styles["circles"]}>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </div>
        </div> */}
      </Card>
    </Link>
  );
};
