import { useTranslation } from "next-i18next";
import Link from "next/link";

export const Slogan = () => {
  const { t } = useTranslation("common");

  return (
    <div className="w-full relative mb-10 hidden md:flex flex-row gap-4 justify-center items-center">
      <div className="inline-flex dark:bg-zinc-900">
        <Link
          href="/"
          title={t("title")}
          className="group inline-flex overflow-hidden text-[length:--size] font-semibold leading-none text-black/50 dark:text-white/50 transition [--size:theme(fontSize.5xl)] [--color:theme(textColor.black)] dark:[--color:theme(textColor.white)] [--duration:280ms] hover:text-[--color]"
        >
          <span className="transition delay-0 duration-[--duration] [text-shadow:0_1em_0_var(--color)] group-hover:-translate-y-[--size]">
            D
          </span>

          <span className="transition delay-[30ms] duration-[--duration] [text-shadow:0_1em_0_var(--color)] group-hover:-translate-y-[--size]">
            o
          </span>

          <span className="transition delay-[60ms] duration-[--duration] [text-shadow:0_1em_0_var(--color)] group-hover:-translate-y-[--size]">
            u
          </span>

          <span className="transition delay-[90ms] duration-[--duration] [text-shadow:0_1em_0_var(--color)] group-hover:-translate-y-[--size]">
            n
          </span>

          <span className="transition delay-[120ms] duration-[--duration] [text-shadow:0_1em_0_var(--color)] group-hover:-translate-y-[--size]">
            i
          </span>

          <span className="transition delay-[150ms] duration-[--duration] [text-shadow:0_1em_0_var(--color)] group-hover:-translate-y-[--size]">
            .
          </span>

          <span className="transition delay-[180ms] duration-[--duration] [text-shadow:0_1em_0_var(--color)] group-hover:-translate-y-[--size]">
            O
          </span>

          <span className="transition delay-[210ms] duration-[--duration] [text-shadow:0_1em_0_var(--color)] group-hover:-translate-y-[--size]">
            n
          </span>

          <span className="transition delay-[240ms] duration-[--duration] [text-shadow:0_1em_0_var(--color)] group-hover:-translate-y-[--size]">
            e
          </span>
        </Link>
      </div>
      <h1 className="text-[30px] h-12 flex items-center pt-2">{t("slogan")}</h1>
    </div>
  );
};
